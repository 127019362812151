/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import "../../Static/css/App.css";

const useStyles = makeStyles({
  leftGrey: {
    background: "#E9EAE8",
    height: "100%",
    float: "left",
    width: "43%",
  },
  rightSide: {
    height: "100%",
    float: "right",
    width: "57%",
    textAlign: "left",
    paddingRight: 45,
    paddingLeft: 25,
  },
  plecas: { marginTop: 80, marginLeft: -23 },
  horizontalLogo: {
    width: 160,
    marginTop: 50,
  },
  proyectName: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Raleway",
  },
  title: {
    fontWeight: 700,
    fontSize: 19,
    fontFamily: "Raleway",
  },
  paragraph: {
    fontWeight: 500,
    fontSize: 8,
    textAlign: "justify",
    fontFamily: "Raleway",
  },
  arrowAlcance: {
    position: "absolute",
    top: "29%",
    left: "40%",
    width: 10,
    height: 10,
  },
  bottomPhoto: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
  },
});

interface Props {
  nombreProyecto: string;
  alcance: string;
  transformUl: (val: React.RefObject<HTMLParagraphElement>) => void;
}
const Alcance: React.FC<Props> = ({ nombreProyecto, alcance, transformUl }) => {
  const classes = useStyles();

  const alcanceRef = React.createRef<HTMLParagraphElement>();

  useEffect(() => {
    if (alcanceRef.current) {
      alcanceRef.current.innerHTML = alcance.replace(/\\n/g, "<br />");
      transformUl(alcanceRef);
    }
  }, [alcance]);

  return (
    <>
      <div
        className="w3-card"
        style={{ position: "relative", width: "520px", height: "657.75px" }}
      >
        <div className={classes.leftGrey}>
          <div className={classes.plecas}>
            <img
              src={require("../../Static/img/plecas.png")}
              alt="plecas"
              width="200px"
            />
          </div>
        </div>
        <div className={classes.rightSide}>
          <div style={{ textAlign: "right" }}>
            <img
              className={classes.horizontalLogo}
              alt="logo_horizontal"
              src={require("../../Static/img/logo_horizontal.png")}
            />
          </div>
          <div style={{ textAlign: "right", marginTop: 5 }}>
            <span className={classes.proyectName}>{nombreProyecto}</span>
          </div>
          <div
            style={{
              marginTop: 40,
            }}
          >
            <h2 className={classes.title}>Alcance</h2>
            <p ref={alcanceRef} className={classes.paragraph}>{`${alcance}`}</p>
          </div>
        </div>
        <img
          className={classes.arrowAlcance}
          src={require("../../Static/img/arrow.png")}
          alt="flecha"
        />
        <img
          className={classes.bottomPhoto}
          src={require("../../Static/img/Image1.png")}
          alt="image_1"
        />
      </div>
    </>
  );
};

export default Alcance;
