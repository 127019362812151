/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import "../../Static/css/interpretacion.css";

const primaryColor = "#FDD900";
const useStyles = makeStyles({
  leftGrey: {
    background: "#E9EAE8",
    height: "100%",
    float: "left",
    width: "43%",
  },
  plecas: { marginTop: 80, marginLeft: -23 },
  plecaAmarilla: {
    width: 200,
    height: 30,
    backgroundColor: primaryColor,
    // clipPath: `polygon(
    //   0%   0%,          /* top left */
    //   100% 0%,          /* top right */
    //   100% 0%,          /* bottom right */
    //   85%  100%,        /* bottom right */
    //   0%   100%         /* bottom left */
    // )`,
  },
  plecaNegra: {
    width: 155,
    height: 30,
    backgroundColor: "black",
    // clipPath: `polygon(
    //   0%   0%,          /* top left */
    //   100% 0%,          /* top right */
    //   100% 0%,          /* bottom right */
    //   85%  100%,        /* bottom right */
    //   0%   100%         /* bottom left */
    // )`,
  },
  rightSide: {
    height: "100%",
    float: "right",
    width: "57%",
    textAlign: "left",
    paddingRight: 45,
    paddingLeft: 25,
  },
  horizontalLogo: {
    width: 160,
    marginTop: 50,
  },
  proyectName: {
    fontFamily: "Raleway",
    fontSize: 12,
    fontWeight: 500,
  },
  title: {
    fontWeight: 700,
    fontSize: 19,
    fontFamily: "Raleway",
  },
  paragraph: {
    fontWeight: 500,
    fontSize: 8,
    textAlign: "justify",
    fontFamily: "Raleway",
  },
  bottomPlecas: {
    position: "absolute",
    bottom: 0,
    zIndex: 99,
    width: "100%",
    height: "9%",
  },
  bottomPlecaAmarilla: {
    position: "absolute",
    left: 0,
    width: "49.71%",
    height: "100%",
    backgroundColor: primaryColor,
    // clipPath: `polygon(
    //   0%   0%,          /* top left */
    //   100% 0%,          /* top right */
    //   100% 0%,          /* bottom right */
    //   85%  100%,        /* bottom right */
    //   0%   100%         /* bottom left */
    // )`,
  },
  bottomPlecaNegra: {
    float: "right",
    width: "70.29%",
    height: "100%",
    backgroundColor: "black",
    // clipPath: `polygon(
    //   15%   0%,          /* top left */
    //   100% 0%,          /* top right */
    //   100% 0%,          /* bottom right */
    //   100%  100%,        /* bottom right */
    //   0%   100%         /* bottom left */
    // )`,
  },
  bottomShadow: {
    position: "absolute",
    width: 57,
    // marginLeft: "29.7%",
    marginLeft: "29.3%",
    // marginLeft: "20%",
    height: "100%",
    backgroundColor: "rgba(48, 48, 48, 0.654)",
    // clipPath: `polygon(
    //   0%   100%,          /* top left */
    //   100% 0%,          /* top right */
    //   100% 0%,          /* bottom right */
    //   100%  100%,        /* bottom right */
    //   0%   100%         /* bottom left */
    // )`,
  },
  arrowInterpretacion: {
    position: "absolute",
    top: "29%",
    left: "40%",
    width: 10,
    height: 10,
    // background: primaryColor,
    // clipPath: `polygon(
    //   100% 50%,
    //   0    0,
    //   0    100%
    // )`,
  },
  arrowObjetivo: {
    marginTop: (props: any) =>
      props.interpretacionHeight === 0
        ? 0
        : props.interpretacionHeight +
          props.interpretacionHeight * 0.15 +
          props.ajusteObjetivoArrow,
    position: "absolute",
    top: "38.5%",
    left: "40%",
    width: 10,
    height: 10,
    // background: primaryColor,
    // clipPath: `polygon(
    //   100% 50%,
    //   0    0,
    //   0    100%
    // )`,
  },
});

interface Props {
  nombreProyecto: string;
  interpretacion: string;
  objetivo: string;
  objetivoArrow: number;
}
const Interpretacion: React.FC<Props> = ({
  nombreProyecto,
  interpretacion,
  objetivo,
  objetivoArrow,
}) => {
  const [interpretacionHeight, setInterpretacionHeight] = React.useState(0);
  const props = {
    interpretacionHeight: interpretacionHeight,
    ajusteObjetivoArrow: objetivoArrow,
  };
  const classes = useStyles(props);
  const interpretacionRef = React.createRef<HTMLParagraphElement>();
  const objetivoRef = React.createRef<HTMLParagraphElement>();

  useEffect(() => {
    if (interpretacionRef.current) {
      setInterpretacionHeight(interpretacionRef.current?.offsetHeight);
      interpretacionRef.current.innerHTML = interpretacion.replace(
        /\\n/g,
        "<br />"
      );
    }
  }, [interpretacion]);

  useEffect(() => {
    if (objetivoRef.current) {
      setInterpretacionHeight(objetivoRef.current?.offsetHeight);
      objetivoRef.current.innerHTML = objetivo.replace(/\\n/g, "<br />");
    }
  }, [objetivo]);

  return (
    <>
      <div
        className="w3-card"
        style={{
          fontFamily: "Raleway",
          position: "relative",
          width: "520px",
          height: "657.75px",
        }}
      >
        <div className={classes.leftGrey}>
          <div className={classes.plecas}>
            <img
              src={require("../../Static/img/plecas.png")}
              alt="plecas"
              width="200px"
            />
          </div>
        </div>
        <div className={classes.rightSide}>
          <div style={{ textAlign: "right" }}>
            <img
              className={classes.horizontalLogo}
              alt="logo_horizontal"
              src={require("../../Static/img/logo_horizontal.png")}
            />
          </div>
          <div style={{ textAlign: "right", marginTop: 5 }}>
            <span className={classes.proyectName}>{nombreProyecto}</span>
          </div>
          <div
            style={{
              marginTop: 40,
              textAlign: "justify",
            }}
          >
            <h2 className={classes.title}>Interpretación</h2>
            <p ref={interpretacionRef} className={classes.paragraph}>
              {interpretacion}
            </p>
          </div>
          <div
            style={{
              marginTop: 40,
            }}
          >
            <h2 className={classes.title}>Objetivo</h2>
            <p ref={objetivoRef} className={classes.paragraph}>
              {objetivo}
            </p>
          </div>
        </div>
        <div className={classes.bottomPlecas}>
          <img
            src={require("../../Static/img/abajo_c.png")}
            alt="plecas"
            width="100%"
          />
          {/* <div className={classes.bottomPlecaAmarilla} />
          <div className={classes.bottomShadow} />
          <div className={classes.bottomPlecaNegra} /> */}
        </div>
        <img
          className={classes.arrowInterpretacion}
          src={require("../../Static/img/arrow.png")}
          alt="flecha"
        />
        <img
          className={classes.arrowObjetivo}
          src={require("../../Static/img/arrow.png")}
          alt="flecha"
        />
        {/* <div className={classes.arrowObjetivo} /> */}
      </div>
    </>
  );
};

export default Interpretacion;
