import app from "firebase/app";
import "firebase/auth";
import "firebase/database";

import {Cotizacion, Project, Projects} from "../../Constants/interfaces";
import {ErrorType} from "../../Constants/errors";

const firebaseConfig = {
    apiKey: "AIzaSyC8hivWmJiNm0yXKSz0ofJ4k9kxVFv6YZk",
    authDomain: "zendasys-cotizadora.firebaseapp.com",
    databaseURL: "https://zendasys-cotizadora.firebaseio.com",
    projectId: "zendasys-cotizadora",
    storageBucket: "zendasys-cotizadora.appspot.com",
    messagingSenderId: "634895105598",
    appId: "1:634895105598:web:2651232f626cda0b991b6f",
};

class Firebase {
    auth: app.auth.Auth;
    database: app.database.Database;

    constructor() {
        app.initializeApp(firebaseConfig);
        this.auth = app.auth();
        this.database = app.database();
    }

    /*************************************************** AUTH *************************************************************/

    /**
     * Regresa el usuario activo
     */
    getAuthUser = (): Promise<app.User> =>
        new Promise((resolve, reject) => {
            this.auth.onAuthStateChanged((authUser) => {
                if (authUser) resolve(authUser);
                reject("No logged user!");
            });
        });

    /**
     * Inicia sesión con correo y contraseña
     * @param email Correo del usuario
     * @param password Contraseña del usuario
     */
    doSignInWithEmailAndPassword = async (email: string, password: string) =>
        this.auth.signInWithEmailAndPassword(email, password);
    /**
     * Cierra la sesión actual
     */
    signout = () => this.auth.signOut();

    /**
     * Resetea la contraseña
     * @param email Manda el mensaje de reinicio de la contraseña al email dado
     */
    sendResetPassword = async (email: string): Promise<void> => {
        try {
            await this.auth.sendPasswordResetEmail(email);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    /*************************************************** Database *************************************************************/
    /**
     * Provee todos los proyectos directamente desde firebase
     */
    getProjects = async (): Promise<Projects> => {
        try {
            const proyectos = (await this.database.ref("proyectos").once("value")).val();
            if (proyectos === null) return {}
            return proyectos
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     * Obtiene la cotización de manera individual
     * @param projectId El ID del proyecto
     */
    getCotizacionByProjectId = async (projectId: string): Promise<Cotizacion> => {
        try {
            const cotizacion = (
                await this.database
                    .ref(`proyectos/${projectId}/cotizacion`)
                    .once("value")).val()
            if (cotizacion === null) return Promise.reject(ErrorType.NOT_FOUND);
            return cotizacion;
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     * Guarda la información modificada de la cotización del
     * respectivo proyecto
     * @param projectId     Se refiere al Id del proyecto en la base de datos
     * @param cotizacion    Se refiere a la información que se debe modificar
     * @param hasNameChange Se refiere a la bandera que determina si existe o no, un cambio en el nombre
     */
    saveCotizacionById =
        async (
            projectId: string,
            cotizacion: Cotizacion,
            hasNameChange: boolean
        ): Promise<void> => {
            try {
                if (hasNameChange) {
                    await this.database.ref(`proyectos/${projectId}/nombre`).set(cotizacion.nombre)
                }
                await this.database.ref(`proyectos/${projectId}/cotizacion`).set(cotizacion)
            } catch (err) {
                return Promise.reject(err);
            }
        }

    /**
     * Crea un nuevo proyecto en la base de datos
     */
    createNewProyect = async (proyecto: Project): Promise<string> => {
        try {
            const newProjectRef = await this.database.ref(`proyectos`).push()
            if (newProjectRef.key == null) return Promise.reject("Not possible to retrieve id");
            proyecto.id = newProjectRef.key;
            await newProjectRef.set(proyecto)
            return newProjectRef.key
        } catch (err) {
            return Promise.reject(err);
        }
    }


}

export default Firebase;
