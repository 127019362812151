/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import "../../Static/css/App.css";

const useStyles = makeStyles({
  leftDecoration: {
    width: "11.62%",
    height: "100%",
    float: "left",
  },
  rightSide: {
    float: "right",
    width: "88.38%",
    // paddingRight: 65,
    // paddingLeft: 60,
  },
  bottomPhoto: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
  },
  logoName: {
    paddingRight: 65,
  },
  horizontalLogo: {
    width: 160,
    marginTop: 50,
  },
  proyectName: {
    fontSize: 12,
    fontFamily: "Raleway",
    fontWeight: 500,
  },
  title: {
    fontWeight: 700,
    fontSize: 19,
    fontFamily: "Raleway",
  },
  paragraph: {
    fontWeight: 500,
    fontSize: 8,
    // wordSpacing: -1,
    textAlign: "justify",
    marginRight: 110,
    maxWidth: 200,
    fontFamily: "Raleway",
  },
  textPart: {
    marginTop: -20,
    textAlign: "left",
    position: "absolute",
    left: (props: any) => props.metodologia.left ?? 200,
    top: (props: any) => props.metodologia.up ?? 100,
  },
});

interface Props {
  nombreProyecto: string;
  metodologiaTitle: string;
  metodologia: string;
  metodologiaMargin: {
    up: number;
    left: number;
  };
  transformUl: (val: React.RefObject<HTMLParagraphElement>) => void;
}
const Metodologia: React.FC<Props> = ({
  nombreProyecto,
  metodologiaTitle,
  metodologia,
  metodologiaMargin,
  transformUl,
}) => {
  const classes = useStyles({ metodologia: metodologiaMargin });
  const metodologiaRef = React.createRef<HTMLParagraphElement>();

  useEffect(() => {
    if (metodologiaRef.current) {
      metodologiaRef.current.innerHTML = metodologia.replace(/\\n/g, "<br />");
      transformUl(metodologiaRef);
    }
  }, [metodologia]);

  return (
    <>
      <div
        className="w3-card"
        style={{ position: "relative", width: "520px", height: "657.75px" }}
      >
        <img
          className={classes.leftDecoration}
          src={require("../.././Static/img/1.png")}
          alt="left"
        />
        <div className={classes.rightSide}>
          <div className={classes.logoName}>
            <div style={{ textAlign: "right" }}>
              <img
                className={classes.horizontalLogo}
                alt="logo_horizontal"
                src={require("../../Static/img/logo_horizontal.png")}
              />
            </div>
            <div style={{ textAlign: "right", marginTop: 5 }}>
              <span className={classes.proyectName}>{nombreProyecto}</span>
            </div>
          </div>
          <div className={classes.textPart}>
            <h2 className={classes.title}>{metodologiaTitle}</h2>
            <p
              ref={metodologiaRef}
              className={classes.paragraph}
            >{`${metodologia}`}</p>
          </div>
        </div>
        <img
          className={classes.bottomPhoto}
          src={require("../../Static/img/Image2.png")}
          alt="image_1"
        />
      </div>
    </>
  );
};

export default Metodologia;
