/* eslint-disable react-hooks/exhaustive-deps */
//#region Import
import React, {useContext, useEffect} from "react";
import {useHistory} from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactDOM from "react-dom";

import {makeStyles} from "@material-ui/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import "../../Static/css/App.css"
import {FirebaseContext} from "../../API/Firebase";
import Controlador from "../Controlador";
import Portada from "../Portada";
import InformeProgeso from "../InformeProgeso";
import Metodologia from "../Metodologia";
import Interpretacion from "../Interpretacion";
import Alcance from "../Alcance";
import Propuesta from "../Propuesta";
import TiempoPrecio from "../TiempoPrecio";
import Firma from "../Firma";
import Bullet from "../../Static/img/bullet.png"
import NotFound from "../../Static/img/not_found.svg"
import {Cotizacion, ErrorMessage} from "../../Constants/interfaces";
import {ErrorType} from "../../Constants/errors";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
//#endregion

const timetext = `Tiempo total de desarrollo: <b>7 semanas</b>\\n
  <ul style="margin-left: 20px;">
    <li>Entrega de producto final: <b>6 semanas postpago anticipo</b></li>
  <li>Implementación y Generación de do- cumentación: <b>1 semana</b></li>
  </ul>`
const costtext = `Monto total de desarrollo:
  <b>$23,500.00 MXN</b>
  <ul style="margin-left: 20px">
  <li><b>Se debe entregar un anticipo del 30% al aprobarse el proyecto, posteriosmen- te dos mensualidades del 30% cada uno. Finalmente un pago del 10% a la semana de la conclusión del proyecto</b></li>
  <li><b>En caso de requerir factura, se cobrará un 16% de IVA extra.</b></li>
  </ul>`
const primaryColor = "#FDD900";
const useStyles = makeStyles({
    leftBar: {
        float: "left",
        height: "100vh",
        width: "30%",
        backgroundColor: primaryColor,
        textAlign: "left",
        padding: "20px 10px",
        color: "white",
        overflowY: "scroll",
    },
    rightBar: {
        padding: "0px 20px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        overflowY: "scroll",
    },
    error: {
        height: '100vh',
        textAlign: 'center',
        "& > div": {
            marginTop: -100
        },
        "& img": {
            width: 200,
            marginBottom: 30,
        },
        "& button": {
            marginTop: 20,
            padding: "8px 13px",
            border: "none",
            background: primaryColor,
            color: "black",
            fontWeight: 700,
            borderRadius: 5
        },
    },
});

interface Props {
    userName: string
}
const Cotizadora: React.FC<Props> = ({userName}) => {
    const firebase = useContext(FirebaseContext);
    const history = useHistory();
    const classes = useStyles();
    const max754Match = useMediaQuery('(max-width:754px)');
    const max424Match = useMediaQuery('(max-width:424px)');
    const [error, setError] = React.useState<ErrorMessage>()
    const [ready, setReady] = React.useState<boolean>(false)
    const [alerta, setAlerta] = React.useState<any>({
        message: "This is an alert",
        open: false,
        severity: "success",
    });
    const Alert = (props: AlertProps) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    };
    const handleClose = () => {
        setAlerta({
            message: "This is an alert",
            open: false,
            severity: "success",
        });
    };
    useEffect(() => {
        try {
            let projectId: any = history.location.pathname.split("/")
            projectId = projectId[projectId.length - 1]
            setIdProyecto(projectId)
            const state = history.location.state as Cotizacion
            if (!state) {
                firebase.getCotizacionByProjectId(projectId)
                    .then(async (cotizacion) => {
                        setValues(cotizacion)
                        setReady(true)
                    })
                    .catch((err) => {
                        setError({
                            type: err,
                            message: err === ErrorType.NOT_FOUND ? "Project Not Found" : err
                        })
                        setReady(true)
                    });
            } else {
                setValues(state)
                setReady(true)
            }
        } catch (err) {
            console.log(err)
        }

    }, [])

    //#region Variables de Cotización
    const [idProyecto, setIdProyecto] = React.useState<string>()
    const [nombreProyecto, setNombreProyecto] = React.useState(
        "Nombre del Projecto"
    );
    const [hasNameChange, setHasNameChange] = React.useState(false)
    const [interpretacion, setInterpretacion] = React.useState("");
    const [objetivo, setObjetivo] = React.useState("");
    const [objetivoArrow, setObjetivoArrow] = React.useState(0);
    const [alcance, setAlcance] = React.useState("");
    const [metodologia, setMetodologia] = React.useState("");
    const [metodologiaTitle, setMetodologiaTitle] = React.useState("");
    const [metodologiaMargin, setMetodologiaMargin] = React.useState({
        up: 150,
        left: 100,
    });
    const [fasesDesarrollo, setFasesDesarrollo] = React.useState({
        weeks: 7,
        values: [
            {name: "Fase de Diseño", content: "MVP", week: 1},
            {name: "Fase de Desarrollo", content: "Desarrollo", week: 5},
            {name: "Fase de Pruebas", content: "Documentación", week: 1},
        ],
    });
    const [iteracionesDesarrollo, setIteracionesDesarrollo] = React.useState([
        "Vistas y Navegación",
        "Auth & Extras",
        "Segunda Funcionalidad",
        "Última Funcionalidad",
    ]);
    const [propuestas, setPropuestas] = React.useState<{ nombre: string; content: string }[]>([{
        nombre: "",
        content: ""
    }]);
    const [
        tiemposEntrega,
        setTiemposEntrega,
    ] = React.useState(timetext);
    const [costos, setCostos] = React.useState(costtext);
    const [nombrePM, setNombrePM] = React.useState("Jesús Perea Villegas");
    const [nombreCiente, setNombreCiente] = React.useState("Nombre De Cliente");
    //#endregion
    useEffect(() => setHasNameChange(true), [nombreProyecto])
    //#region Métodos de Apoyo
    const setValues = (cotizacion: Cotizacion) => {
        setNombreProyecto(cotizacion.nombre)
        if (cotizacion.interpretacion)
            setInterpretacion(cotizacion.interpretacion)
        if (cotizacion.objetivo)
            setObjetivo(cotizacion.objetivo)
        if (cotizacion.objetivoArrow)
            setObjetivoArrow(cotizacion.objetivoArrow)
        if (cotizacion.alcance)
            setAlcance(cotizacion.alcance)
        if (cotizacion.metodologia)
            setMetodologia(cotizacion.metodologia)
        if (cotizacion.metodologiaTitle)
            setMetodologiaTitle(cotizacion.metodologiaTitle)
        if (cotizacion.metodologiaMargin)
            setMetodologiaMargin(cotizacion.metodologiaMargin)
        if (cotizacion.fasesDesarrollo)
            setFasesDesarrollo(cotizacion.fasesDesarrollo)
        if (cotizacion.iteracionesDesarrollo)
            setIteracionesDesarrollo(cotizacion.iteracionesDesarrollo)
        if (cotizacion.propuestas)
            setPropuestas(cotizacion.propuestas)
        if (cotizacion.tiemposEntrega)
            setTiemposEntrega(cotizacion.tiemposEntrega)
        if (cotizacion.costos)
            setCostos(cotizacion.costos)
        if (cotizacion.nombrePM)
            setNombrePM(cotizacion.nombrePM)
        else
            setNombrePM(userName)
        if (cotizacion.nombreCiente)
            setNombreCiente(cotizacion.nombreCiente)
    }
    const save = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        if (idProyecto) {
            setAlerta({
                message: "Cargando...",
                open: true,
                severity: "info"
            })
            try {
                await firebase.saveCotizacionById(idProyecto, {
                    nombre: nombreProyecto,
                    interpretacion,
                    objetivo,
                    objetivoArrow,
                    alcance,
                    metodologia,
                    metodologiaTitle,
                    metodologiaMargin,
                    fasesDesarrollo,
                    iteracionesDesarrollo,
                    propuestas,
                    tiemposEntrega,
                    costos,
                    nombrePM,
                    nombreCiente,
                }, hasNameChange);
                setAlerta({
                    message: "Guardado Éxitosamente",
                    open: true,
                    severity: "success"
                })
            }catch (err){
                setAlerta({
                    message: err.toString(),
                    open: true,
                    severity: "error"
                })
            }
        } else {
            setAlerta({
                message: "No se detecto ningún ID",
                open: true,
                severity: "error"
            })
        }
    }
    const downloadPDF = async (e: any) => {
        e.preventDefault();
        const element = document.getElementById("documento");
        if (element === null) return;
        const pdf = new jsPDF();
        let page = element.children[0];
        await create_page(page, pdf)
        for (let i = 1; i < element.children.length; i++) {
            pdf.addPage();
            let page = element.children[i];
            await create_page(page, pdf)
        }
        pdf.save(`${nombreProyecto.replace(/[ ]/g, "_")}.pdf`);
    };
    const create_page = async (page: any, pdf: jsPDF) => {
        const canvas = await html2canvas(page as HTMLElement);
        const imgData = canvas.toDataURL("image/png");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        pdf.internal.pageSize.height = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdf.internal.pageSize.height);
    }
    const return_lis = (
        words: (string | JSX.Element)[],
        bulletSize: number = 10
    ): JSX.Element[] =>
        words.map((word, index) =>
            typeof word === "string" ? (
                return_normal_li(word, index, bulletSize)
            ) : (
                <div className="row">
                    <div className="col-1">
                        <img
                            key={index}
                            style={{
                                marginRight: 10,
                                display: "inline-block",
                            }}
                            width={bulletSize}
                            src={Bullet}
                            alt="bullet"
                        />
                    </div>
                    <div className="col" style={{marginLeft: -14}}>
                        <div style={{fontFamily: "Raleway"}} key={`${index}text`}>
                            {word}
                        </div>
                    </div>
                </div>
            )
        );
    const return_normal_li = (
        word: string,
        index: number,
        bulletSize: number
    ) => (
        <li key={index} style={{listStyle: "none"}}>
            <img
                key={index}
                style={{marginRight: 10}}
                width={bulletSize}
                src={Bullet}
                alt="bullet"
            />
            {(
                <span
                    key={`${index}text`}
                    style={{fontWeight: 500, fontFamily: "Raleway"}}
                >
          {word}
        </span>
            )}
        </li>
    );
    const transformUl = (
        metodologiaRef: React.RefObject<HTMLParagraphElement>
    ) => {
        const children = metodologiaRef.current!.querySelectorAll("ul");
        //Recorremos cada element ul
        for (let i = 0; i < children.length; i++) {
            //Lista de textos por cada ul y li
            const lista_textos: (string | JSX.Element)[] = [];
            //Aquí sacamos cada uno de los ul
            const ul_element = children[i];
            //De cada uno de los Ul, obtenemos sus li
            const li_list = ul_element.querySelectorAll("li");
            for (let j = 0; j < li_list.length; j++) {
                //Recorremos cada uno de ellos para sacar su texto
                const li_element = li_list[j];
                //Utilizando jQuery, sacamos el texto y lo colocamos
                const has_html_content = Array.from(li_element.children).some(
                    (e) => e.nodeType !== 3
                );
                if (has_html_content) {
                    const li_react = (
                        <div dangerouslySetInnerHTML={{__html: li_element.innerHTML}}/>
                    );
                    lista_textos.push(li_react);
                } else {
                    //En la lista designada
                    lista_textos.push($(li_element).text());
                }
            }
            //Si no hay ningun li dentro de los ul,
            //nos seguimos al siguiente ul en caso de existir
            if (lista_textos.length === 0) break;
            //En caso de que existan elementos, sacamos el css de ul
            const ul_style = ul_element.style;
            //Creamos un nuevo div en memoria
            const newDiv = document.createElement("div");
            newDiv.style.cssText = ul_style.cssText;
            //Le decimos a React que convierta lo que regresa return_lis
            //a un elemento dde HTML nativo y que lo coloqué en el nuevo
            //div creado anteriormente
            ReactDOM.render(return_lis(lista_textos, 5), newDiv);
            //Finalmente remplazamos el ul con el nuevo div
            ul_element.replaceWith(newDiv);
        }
    };
    //#endregion
    //#region Loading
    if (!ready)
        return (
            <div
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    height: "100vh",
                    width: "100%",
                }}
            >
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    //#endregion
    //#region Dibujar parte de Error
    if (error)
        return <div className={`${classes.error} w3-display-container`}>
            <div className={`w3-display-middle`}>
                <img src={NotFound} alt={"Not Found"}/>
                <h1 style={{
                    fontSize: max424Match ? "16px" : max754Match ? '24px' : '32px'
                }}>{error.message}</h1>
                <button onClick={() => history.push("/")}>Get Back</button>
            </div>
        </div>
    //#endregion

    return (<>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={alerta.open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert style={{fill: 'white'}} onClose={handleClose} severity={alerta.severity}>
                    <span style={{color: 'white'}}>{alerta.message}</span>
                </Alert>
            </Snackbar>
            <div className="App">
                <div className={classes.leftBar}>
                    <Controlador
                        key={'unique key'}
                        nombreProyecto={nombreProyecto}
                        setNombreProyecto={setNombreProyecto}
                        interpretacion={interpretacion}
                        setInterpretacion={setInterpretacion}
                        objetivo={objetivo}
                        setObjetivo={setObjetivo}
                        objetivoArrow={objetivoArrow}
                        setObjetivoArrow={setObjetivoArrow}
                        alcance={alcance}
                        setAlcance={setAlcance}
                        metodologia={metodologia}
                        setMetodologia={setMetodologia}
                        metodologiaTitle={metodologiaTitle}
                        setMetodologiaTitle={setMetodologiaTitle}
                        metodologiaMargin={metodologiaMargin}
                        setMetodologiaMargin={setMetodologiaMargin}
                        fasesDesarrollo={fasesDesarrollo}
                        setFasesDesarrollo={setFasesDesarrollo}
                        iteracionesDesarrollo={iteracionesDesarrollo}
                        setIteracionesDesarrollo={setIteracionesDesarrollo}
                        propuestas={propuestas}
                        setPropuestas={setPropuestas}
                        setTiemposEntrega={setTiemposEntrega}
                        setCostos={setCostos}
                        tiemposEntrega={tiemposEntrega}
                        costos={costos}
                        nombrePM={nombrePM}
                        setNombrePM={setNombrePM}
                        setNombreCiente={setNombreCiente}
                        downloadPDF={downloadPDF}
                        save={save}
                    />
                </div>
                <div className={classes.rightBar}>
                    <div id="documento" style={{marginTop: 40}}>
                        <Portada nombreProyecto={nombreProyecto} return_lis={return_lis}/>
                        <Interpretacion
                            interpretacion={interpretacion}
                            objetivo={objetivo}
                            objetivoArrow={objetivoArrow}
                            nombreProyecto={nombreProyecto}
                        />
                        <Alcance
                            transformUl={transformUl}
                            nombreProyecto={nombreProyecto}
                            alcance={alcance}
                        />
                        <Metodologia
                            nombreProyecto={nombreProyecto}
                            metodologia={metodologia}
                            metodologiaTitle={metodologiaTitle}
                            metodologiaMargin={metodologiaMargin}
                            transformUl={transformUl}
                        />
                        <InformeProgeso
                            nombreProyecto={nombreProyecto}
                            fasesDesarrollo={fasesDesarrollo}
                            iteracionesDesarrollo={iteracionesDesarrollo}
                        />
                        <Propuesta
                            nombreProyecto={nombreProyecto}
                            propuestas={propuestas}
                            transformUl={transformUl}
                        />
                        <TiempoPrecio
                            nombreProyecto={nombreProyecto}
                            transformUl={transformUl}
                            tiemposEntrega={tiemposEntrega}
                            costos={costos}
                        />
                        <Firma
                            nombreProyecto={nombreProyecto}
                            nombrePM={nombrePM}
                            nombreCiente={nombreCiente}
                        />
                    </div>
                    <div style={{height: 40}}/>
                </div>
            </div>
        </>
    );
}

export default Cotizadora;