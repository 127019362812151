import {makeStyles} from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles({
    leftDecoration: {
        width: "11.62%",
        height: "100%",
        float: "left",
    },
    rightSide: {
        float: "right",
        width: "88.38%",
        // paddingRight: 65,
        // paddingLeft: 60,
    },
    logoName: {
        paddingRight: 65,
    },
    horizontalLogo: {
        width: 160,
        marginTop: 50,
    },
    proyectName: {
        fontSize: 12,
        fontFamily: "Raleway",
        fontWeight: 500,
    },
    textPart: {
        textAlign: "left",
        paddingLeft: 45,
    },
    title: {
        fontWeight: 700,
        fontSize: 19,
        fontFamily: "Raleway",
    },
    subTitle: {
        fontWeight: 700,
        fontSize: 13,
        fontFamily: "Raleway",
    },
    paragraph: {
        fontWeight: 500,
        fontSize: 8,
        // wordSpacing: -1,
        textAlign: "justify",
        marginRight: 110,
        maxWidth: 200,
        fontFamily: "Raleway",
    },
    fasesTable: {
        fontSize: 8,
        fontFamily: "Raleway",
        "& tr th": {
            fontFamily: "Raleway",
            fontWeight: 500,
            color: "white",
            backgroundColor: "#0F3E6A",
        },
        "& tr .emptyCell": {
            backgroundColor: "white",
        },
        "& tr .weekCell": {
            padding: "0px 3px",
            textAlign: "center",
        },
        "& tr .greyCell": {
            backgroundColor: "#C3C3CD",
        },
        "& tr td": {
            fontFamily: "Raleway",
            fontWeight: 500,
            textAlign: "center",
            color: "white",
        },
        "& tr .phaseName": {
            textAlign: "left",
            backgroundColor: "#05CAA5",
            width: 80,
        },
    },
    desarrolloTable: {
        fontSize: 8,
        fontFamily: "Raleway",
        "& tr th": {
            fontFamily: "Raleway",
            fontWeight: 500,
            color: "white",
            backgroundColor: "#0F3E6A",
        },
        "& tr .emptyCell": {
            backgroundColor: "white",
        },
        "& tr .weekCell": {
            padding: "0px 3px",
            textAlign: "center",
        },
        "& tr .greyCell": {
            backgroundColor: "#C3C3CD",
        },
        "& tr td": {
            fontFamily: "Raleway",
            fontWeight: 500,
            textAlign: "center",
            color: "white",
        },
        "& tr .phaseName": {
            textAlign: "center",
            backgroundColor: "#05CAA5",
            width: 80,
        },
    },
    plecas_right: {
        position: "absolute",
        right: 0,
        bottom: 40,
    },
});

interface Props {
    nombreProyecto: string;
    fasesDesarrollo: {
        weeks: number;
        values: { name: string; content: string; week: number }[];
    };
    iteracionesDesarrollo: string[];
}

const InformeProgeso: React.FC<Props> = ({
                                             nombreProyecto,
                                             fasesDesarrollo,
                                             iteracionesDesarrollo,
                                         }) => {
    const classes = useStyles({});
    const listOfColors = ["#FF5426", "#F9C63B", "#0CA789", "#8ED04E"];

    const calcCells = (fase: any, num: number, arr: any) => {
        const back = num !== 0 ? arr[num - 1].week + num - 1 : 0;
        const mine = fase.week;
        const front = fasesDesarrollo.weeks - (mine + back);
        return (
            <>
                {back !== 0 ? <td className="greyCell" colSpan={back}/> : null}
                <td
                    style={{background: listOfColors[num % listOfColors.length]}}
                    colSpan={mine}
                >
                    {fase.content}
                </td>
                {front !== 0 ? <td className="greyCell" colSpan={front}/> : null}
            </>
        );
    };

    return (
        <>
            <div
                className="w3-card"
                style={{position: "relative", width: "520px", height: "657.75px"}}
            >
                <img
                    className={classes.leftDecoration}
                    src={require("../.././Static/img/1.png")}
                    alt="left"
                />
                <div className={classes.rightSide}>
                    <div className={classes.logoName}>
                        <div style={{textAlign: "right"}}>
                            <img
                                className={classes.horizontalLogo}
                                alt="logo_horizontal"
                                src={require("../../Static/img/logo_horizontal.png")}
                            />
                        </div>
                        <div style={{textAlign: "right", marginTop: 5}}>
                            <span className={classes.proyectName}>{nombreProyecto}</span>
                        </div>
                    </div>
                    <div className={classes.textPart}>
                        <h2 className={classes.title}>Informes de progreso</h2>
                        <h5 className={classes.subTitle}>Fases del Proyeto</h5>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginLeft: -45,
                                marginBottom: 100,
                            }}
                        >
                            <table className={classes.fasesTable}>
                                <thead>
                                    <tr>
                                        <th className="emptyCell"/>
                                        {[...Array(fasesDesarrollo.weeks)].map((_, i) => (
                                            <th key={i} className="weekCell">Semana {i + 1}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {fasesDesarrollo.values.map((fase, num, arr) => (
                                        <tr key={num}>
                                            <td className="phaseName">{fase.name}</td>
                                            {calcCells(fase, num, arr)}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <h5 className={`${classes.subTitle} mt-5`}>Fases de Desarrollo</h5>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginLeft: -45,
                            }}
                        >
                            <table className={classes.desarrolloTable}>
                                <thead>
                                    <tr>
                                        {[...Array(iteracionesDesarrollo.length)].map((_, i) => (
                                            <th key={i} className="weekCell">Iteración {i + 1}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {iteracionesDesarrollo.map((iteracion, index) => (
                                            <td
                                                key={index}
                                                style={{
                                                    background: listOfColors[index % listOfColors.length],
                                                }}
                                                className="phaseName"
                                            >
                                                {iteracion}
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <img
                    className={classes.plecas_right}
                    width={200}
                    alt="plecas_right"
                    src={require("../../Static/img/plecas_derecha.png")}
                />
            </div>
        </>
    );
};

export default InformeProgeso;
