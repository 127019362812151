/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";

const useStyles = makeStyles({
  leftDecoration: {
    width: "11.62%",
    height: "100%",
    float: "left",
  },
  rightSide: {
    float: "right",
    width: "88.38%",
  },
  logoName: {
    paddingRight: 65,
  },
  horizontalLogo: {
    width: 160,
    marginTop: 50,
  },
  proyectName: {
    fontSize: 12,
    fontFamily: "Raleway",
    fontWeight: 500,
  },
  textPart: {
    textAlign: "left",
    paddingLeft: 45,
  },
  title: {
    fontWeight: 700,
    fontSize: 19,
    fontFamily: "Raleway",
  },
  subTitle: {
    fontWeight: 700,
    fontSize: 13,
    fontFamily: "Raleway",
  },
  paragraph: {
    fontWeight: 500,
    fontSize: 8,
    // wordSpacing: -1,
    textAlign: "justify",
    marginRight: 110,
    maxWidth: 200,
    fontFamily: "Raleway",
  },
  plecas_right: {
    position: "absolute",
    right: 0,
    bottom: 40,
  },
});

interface Props {
  nombreProyecto: string;
  propuestas: { nombre: string; content: string }[];
  transformUl: (val: React.RefObject<HTMLParagraphElement>) => void;
}
const Propuesta: React.FC<Props> = ({
  nombreProyecto,
  propuestas,
  transformUl,
}) => {
  const classes = useStyles();

  const propuestasRefList = propuestas.map((e) =>
    React.createRef<HTMLParagraphElement>()
  );

  useEffect(() => {
    propuestasRefList.forEach((propuestaRef, index) => {
      if (propuestaRef.current) {
        propuestaRef.current.innerHTML = propuestas[index].content.replace(
          /\\n/g,
          "<br />"
        );
        transformUl(propuestaRef);
      }
    });
  }, [propuestas]);

  return (
    <>
      {propuestas.map((e, i) => (
        <div
            key={i}
          className="w3-card"
          style={{ position: "relative", width: "520px", height: "657.75px" }}
        >
          <img
            className={classes.leftDecoration}
            src={require("../.././Static/img/1.png")}
            alt="left"
          />
          <div className={classes.rightSide}>
            <div className={classes.logoName}>
              <div style={{ textAlign: "right" }}>
                <img
                  className={classes.horizontalLogo}
                  alt="logo_horizontal"
                  src={require("../../Static/img/logo_horizontal.png")}
                />
              </div>
              <div style={{ textAlign: "right", marginTop: 5 }}>
                <span className={classes.proyectName}>{nombreProyecto}</span>
              </div>
            </div>
            <div className={classes.textPart}>
              {i === 0 ? <h2 className={classes.title}>Propuesta</h2> : null}
              <h5 className={classes.subTitle}>{propuestas[i].nombre}</h5>
              <p
                ref={propuestasRefList[i]}
                className={classes.paragraph}
              >{`${propuestas[i].content}`}</p>
            </div>
            <img
              className={classes.plecas_right}
              width={200}
              alt="plecas_right"
              src={require("../../Static/img/plecas_derecha.png")}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default Propuesta;
