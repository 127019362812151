import React, { useState, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

import "./style.scss";
import { FirebaseContext } from "../../API/Firebase";

interface Props {}
const primaryColor = "#FDD900";

const LogIn: React.FC<Props> = () => {
  const firebase = useContext(FirebaseContext);
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alerta, setAlerta] = React.useState<any>({
    message: "This is an alert",
    open: false,
    severity: "success",
  });
  const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    setAlerta({
      message: "This is an alert",
      open: false,
      severity: "success",
    });
  };

  const loginButton = async (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    let temp = {
      message: "",
      open: true,
      severity: "error",
    };
    if (email === "") {
      temp.message = "Email can't be empty";
      setAlerta(temp);
      return;
    } else if (password === "") {
      temp.message = "Password can't be empty";
      setAlerta(temp);
      return;
    }
    temp.message = "Log in success";
    temp.severity = "success";
    try {
      await firebase.doSignInWithEmailAndPassword(email, password);
      await firebase.getAuthUser();
      setAlerta(temp);
      history.push("/");
    } catch (err) {
      console.log(err);
      temp.message = err.message;
      temp.severity = "error";
      setAlerta(temp);
    }
  };

  const resetPass = (e: any) => {
    if (email === "") {
      setAlerta({
        message: "Por favor provee un correo",
        open: true,
        severity: "info",
      });
      return;
    }
    firebase
      .sendResetPassword(email)
      .then(() =>
        setAlerta({
          message: "Se ha mandado un correo para resetear la contraseña",
          open: true,
          severity: "success",
        })
      )
      .catch((err: any) =>
        setAlerta({
          message: err,
          open: true,
          severity: "error",
        })
      );
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alerta.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alerta.severity}>
            <span style={{color: 'white'}}>{alerta.message}</span>
        </Alert>
      </Snackbar>
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg">
            <span className="r" />
            <span className="r s" />
            <span className="r s" />
            <span className="r" />
          </div>
          <div className="card">
            <div style={{ textAlign: "center" }} className="card-body">
              <LockOpenIcon
                className="auth-icon mb-4"
                style={{
                  color: primaryColor,
                  fontSize: 38,
                }}
              />
              <h2 style={{ fontWeight: 100, fontSize: 26 }} className="mb-4">
                Login
              </h2>
              <div className="input-group mb-3">
                <input
                  spellCheck={false}
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input-group mb-5">
                <input
                  type="password"
                  className="form-control"
                  placeholder="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") loginButton();
                  }}
                />
              </div>

              <button
                onClick={loginButton}
                className="btn btn-primary-new shadow-2 mb-4"
                style={{ backgroundColor: primaryColor, color: "white" }}
              >
                Login
              </button>

              <div style={{ fontSize: 14 }}>
                <p className="mb-2 text-muted">
                  Forgot password?{" "}
                  <NavLink onClick={resetPass} to="#">
                    Reset
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogIn;
