/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from "react";
import "./Static/css/App.css";
import LogIn from "./Components/LogIn";
import {Switch, Route, useHistory} from "react-router-dom";
import ProyectosPage from "./Components/ProyectosPage";
import Cotizadora from "./Components/Cotizadora";

import {FirebaseContext} from "./API/Firebase";

function App() {
    const firebase = useContext(FirebaseContext);
    const history = useHistory();
    const [ready, setReady] = useState(false);
    const [userName, setUserName] = useState<string>("")

    useEffect(() => {
        firebase
            .getAuthUser()
            .then((authUser) => {
                setUserName(authUser.displayName ?? authUser.uid)
                setReady(true);
            })
            .catch((err) => {
                setReady(true);
                history.push("/login");
            });
    }, []);

    if (!ready)
        return (
            <div
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    height: "100vh",
                    width: "100%",
                }}
            >
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );

    return (
        <Switch>
            <Route path="/login">
                <LogIn/>
            </Route>
            <Route path="/cotizacion/:id">
                <Cotizadora userName={userName} />
            </Route>
            <Route path="/">
                <ProyectosPage userName={userName} />
            </Route>
        </Switch>
    );
}

export default App;
