/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import "../../Static/css/App.css";

const useStyles = makeStyles({
  leftDecoration: {
    width: "11.62%",
    height: "100%",
    float: "left",
  },
  rightSide: {
    float: "right",
    width: "88.38%",
  },
  logoName: {
    paddingRight: 65,
  },
  horizontalLogo: {
    width: 160,
    marginTop: 50,
  },
  proyectName: {
    fontSize: 12,
    fontFamily: "Raleway",
    fontWeight: 500,
  },
  textPart: {
    textAlign: "left",
    paddingLeft: 45,
  },
  title: {
    fontWeight: 700,
    fontSize: 19,
    fontFamily: "Raleway",
  },
  subTitle: {
    fontWeight: 700,
    fontSize: 13,
    fontFamily: "Raleway",
  },
  paragraph: {
    fontWeight: 500,
    fontSize: 8,
    // wordSpacing: -1,
    textAlign: "justify",
    marginRight: 110,
    maxWidth: 200,
    fontFamily: "Raleway",
  },
  plecas_right: {
    position: "absolute",
    right: 0,
    bottom: 40,
  },
});

interface Props {
  nombreProyecto: string;
  tiemposEntrega: string;
  costos: string;
  transformUl: (val: React.RefObject<HTMLParagraphElement>) => void;
}
const TiempoPrecio: React.FC<Props> = ({
  nombreProyecto,
  tiemposEntrega,
  transformUl,
  costos,
}) => {
  const classes = useStyles();
  const propuestasRefList = React.createRef<HTMLParagraphElement>();
  const costosRefList = React.createRef<HTMLParagraphElement>();

  useEffect(() => {
    if (propuestasRefList.current) {
      propuestasRefList.current.innerHTML = tiemposEntrega.replace(
        /\\n/g,
        "<br />"
      );
      transformUl(propuestasRefList);
    }
  }, [tiemposEntrega]);

  useEffect(() => {
    if (costosRefList.current) {
      costosRefList.current.innerHTML = costos.replace(/\\n/g, "<br />");
      transformUl(costosRefList);
    }
  }, [costos]);

  return (
    <>
      <div
        className="w3-card"
        style={{ position: "relative", width: "520px", height: "657.75px" }}
      >
        <img
          className={classes.leftDecoration}
          src={require("../.././Static/img/1.png")}
          alt="left"
        />
        <div className={classes.rightSide}>
          <div className={classes.logoName}>
            <div style={{ textAlign: "right" }}>
              <img
                className={classes.horizontalLogo}
                alt="logo_horizontal"
                src={require("../../Static/img/logo_horizontal.png")}
              />
            </div>
            <div style={{ textAlign: "right", marginTop: 5 }}>
              <span className={classes.proyectName}>{nombreProyecto}</span>
            </div>
          </div>
          <div className={classes.textPart}>
            <h2 className={classes.title}>Tiempos de entrega</h2>
            <p ref={propuestasRefList} className={classes.paragraph}>
              {tiemposEntrega}
            </p>
            <h2 className={classes.title}>Costo</h2>
            <p ref={costosRefList} className={classes.paragraph}>
              {costos}
            </p>
          </div>
          <img
            className={classes.plecas_right}
            width={200}
            alt="plecas_right"
            src={require("../../Static/img/plecas_derecha.png")}
          />
        </div>
      </div>
    </>
  );
};

export default TiempoPrecio;
