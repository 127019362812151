/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from 'react'
import {FirebaseContext} from "../../API/Firebase";
import {useHistory} from 'react-router-dom';
import {Projects} from "../../Constants/interfaces";
import {Box, Card, Modal} from '@material-ui/core';
import PortraitImg from '../../Static/img/portrait.png'
import Addicon from "../../Static/img/boton-circular-plus.svg"
import LogoutIcon from "../../Static/img/logout.svg";
import {makeStyles} from "@material-ui/styles";
import $ from 'jquery'

const primaryColor = "#FDD900";
const usesStyles = makeStyles({
    card: {
        height: 150,
        margin: '20px 0px',
        padding: 10,
        textAlign: 'left',
        cursor: "pointer",
        '&:hover': {
            background: '#FEF3BD'
        }
    },
    paper: {
        width: 400,
        backgroundColor: "white",
        outline: 'none',
        padding: 20,
        position: "absolute",
        marginLeft: -200,
        left: '50%',
        top: '50%',
        marginTop: -200,
        textAlign: "center",
        '& form': {
            textAlign: 'left'
        }
    },
})

interface Props {
    userName: string
}

const ProyectosPage: React.FC<Props> = ({userName}) => {
    const firebase = useContext(FirebaseContext)
    const history = useHistory()
    const classes = usesStyles()
    const [ready, setReady] = useState(false);
    const [projects, setProjects] = useState<Projects>({})
    const [open, setOpen] = useState(false)
    useEffect(() => {
        firebase.getProjects().then(async (proyectos) => {
            try {
                setProjects(proyectos)
                setReady(true)
            } catch (err) {
                console.log(err)
                setReady(true)
            }
        }).catch((err) => {
            console.log(err);
            setReady(true);
        })
    }, [])

    const logout = async (e: any) => {
        e.preventDefault()
        await firebase.signout();
        history.push("/login");
    };
    const addProject = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault()
            const nombreProyecto = ($(e.currentTarget)[0] as any)[0].value
            const nombreCliente = ($(e.currentTarget)[0] as any)[1].value
            const temp_project = {
                creador: userName,
                ultimo_modificador: userName,
                cliente: nombreCliente,
                nombre: nombreProyecto,
                id: '',
                fecha_creacion: (new Date()).toLocaleDateString('es', {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                }),
                fecha_actualizacion: (new Date()).toLocaleDateString('es', {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                }),
                cotizacion: {
                    nombre: nombreProyecto,
                    nombrePM: userName
                }
            };
            const new_id = await firebase.createNewProyect(temp_project);
            const temp_projects = {...projects}
            temp_projects[new_id] = temp_project
            setProjects(temp_projects)
            setOpen(false)
        } catch (err) {
            console.log(err)
        }
    };
    const goToCotizadora = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>, project_key: string) => {
        e.preventDefault()
        history.push(`/cotizacion/${projects[project_key].id}`, projects[project_key].cotizacion)
    }

    if (!ready)
        return (
            <div
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    height: "100vh",
                    width: "100%",
                }}
            >
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );

    const each_project = (project_key: string, index: number) => {
        return <>
            <Card
                key={index}
                onClick={(e) => goToCotizadora(e, project_key)}
                className={`${classes.card} col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12`}>
                <img
                    key={`image ${index}`}
                    style={{
                        display: 'inline-block',
                        paddingTop: 30
                    }}
                    width={50}
                    src={PortraitImg}
                    alt={"portada"}/>
                <div
                    key={`div ${index}`}
                    style={{
                        display: 'inline-block',
                        marginLeft: 70,
                        marginTop: -100,
                        overflowY: 'scroll'
                    }}>
                    <h6 key={`dh6 ${index}`} style={{fontSize: 14}}>{projects[project_key].nombre}</h6>
                    <h6 key={`h62 ${index}`}
                        style={{fontSize: 14, marginTop: -10, marginBottom: 5}}>{projects[project_key].cliente}</h6>
                    <div
                        key={`ddiv indie div ${index}`}
                        style={{
                            fontSize: 12,
                            fontWeight: 500
                        }}
                    >
                        <div key={`divdivdiv  ${index}`}>
                            <b key={`b ${index}`}>Creado:</b>&nbsp;&nbsp;{projects[project_key].fecha_creacion} por
                        </div>
                        <div key={`divdivdiv2 ${index}`}>
                            {projects[project_key].creador}
                        </div>
                        <div key={`divdivdiv3 ${index}`}>
                            <b key={`b2 ${index}`}>Actualizado:</b>&nbsp;&nbsp;{projects[project_key].fecha_actualizacion} por
                        </div>
                        <div key={`divdivdiv4 ${index}`}>
                            {projects[project_key].ultimo_modificador}
                        </div>
                    </div>
                </div>
            </Card></>
    }

    return <>
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Box boxShadow={3} className={classes.paper}>
                <h2 id="simple-modal-title">Crea un nuevo proyecto</h2>
                <form onSubmit={addProject}>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Nombre del Proyecto</label>
                        <input required type="text" className="form-control" id="exampleInputEmail1"
                               aria-describedby="emailHelp"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Nombre del Cliente</label>
                        <input required type="text" className="form-control" id="exampleInputPassword1"/>
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </Box>
        </Modal>
        <div className="w3-display-container" style={{height: 75, background: primaryColor}}>
            <div className="w3-padding w3-display-left">
                <h3 style={{color: 'black'}}>Cotizadora</h3>
            </div>
            <div className="w3-padding w3-display-right">
                <div onClick={() => setOpen(true)} style={{
                    border: 'dashed',
                    padding: 10,
                    borderColor: 'white',
                    display: 'inline-block',
                    cursor: 'pointer'
                }}>
                    <img style={{fill: 'white'}} width={30} src={Addicon} alt={"portada"}/>
                </div>
                <div onClick={logout} style={{
                    border: 'dashed',
                    padding: 10,
                    borderColor: 'white',
                    display: 'inline-block',
                    marginLeft: 10,
                    cursor: 'pointer'
                }}>
                    <img style={{fill: 'white'}} width={30} src={LogoutIcon} alt={"portada"}/>
                </div>
            </div>
        </div>
        <div>
            {Object.keys(projects).length === 0 ?
                <>
                    <div
                        className={`w3-display-container`}
                        style={{
                            height: '100vh',
                            textAlign: 'center',
                            marginTop: -180
                        }}
                    >
                        <div className={`w3-display-middle`}>
                            <h1 style={{width: '100vw'}}>No hay proyectos</h1>
                            <h2 style={{width: '100vw'}}>¡Crea uno!</h2>
                            <button
                                style={{
                                    marginTop: 20,
                                    padding: "8px 13px",
                                    border: "none",
                                    background: primaryColor,
                                    color: "black",
                                    fontWeight: 700,
                                    borderRadius: 5
                                }}
                                onClick={() => setOpen(true)}>Empezar ahora</button>
                        </div>
                    </div>
                </> :
                <div className="container">
                    <div className="row justify-content-center">
                        {Object.keys(projects).map(each_project)}
                    </div>
                </div>
            }
        </div>
    </>;
}

export default ProyectosPage;