/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from "@material-ui/styles";
import React from "react";
import "../../Static/css/App.css";

const useStyles = makeStyles({
  leftDecoration: {
    width: "11.62%",
    height: "100%",
    float: "left",
  },
  rightSide: {
    float: "right",
    width: "88.38%",
  },
  logoName: {
    paddingRight: 65,
  },
  horizontalLogo: {
    width: 160,
    marginTop: 50,
  },
  proyectName: {
    fontSize: 12,
    fontFamily: "Raleway",
    fontWeight: 500,
  },
  date: {
    fontSize: 10,
    fontFamily: "Raleway",
    fontWeight: 500,
    whiteSpace: "pre",
  },
  textPart: {
    textAlign: "left",
    paddingLeft: 45,
    marginTop: 50,
  },
  title: {
    fontWeight: 700,
    fontSize: 19,
    fontFamily: "Raleway",
  },
  subTitle: {
    fontWeight: 700,
    fontSize: 13,
    fontFamily: "Raleway",
  },
  paragraph: {
    fontWeight: 500,
    fontSize: 10,
    // wordSpacing: -1,
    textAlign: "justify",
    marginRight: 65,
    fontFamily: "Raleway",
  },
  firmas: {
    position: "absolute",
    bottom: 40,
    left: "18.5%",
    marginRight: 65,
    width: "80%",
  },
  textoFirmas: {
    fontWeight: 500,
    fontSize: 8,
    // wordSpacing: -1,
    textAlign: "center",
    fontFamily: "Raleway",
  },
});

interface Props {
  nombreProyecto: string;
  nombrePM: string;
  nombreCiente: string;
}
const Firma: React.FC<Props> = ({ nombreProyecto, nombrePM, nombreCiente }) => {
  const classes = useStyles();
  const event = new Date();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = event.toLocaleDateString("es-ES", options);

  return (
    <>
      <div
        className="w3-card"
        style={{ position: "relative", width: "520px", height: "657.75px" }}
      >
        <img
          className={classes.leftDecoration}
          src={require("../.././Static/img/1.png")}
          alt="left"
        />
        <div className={classes.rightSide}>
          <div className={classes.logoName}>
            <div style={{ textAlign: "right" }}>
              <img
                className={classes.horizontalLogo}
                alt="logo_horizontal"
                src={require("../../Static/img/logo_horizontal.png")}
              />
            </div>
            <div style={{ textAlign: "right", marginTop: 5 }}>
              <span className={classes.proyectName}>{nombreProyecto}</span>
              <br />
              <pre style={{ marginTop: 10 }} className={classes.date}>
                A {date}
                <br />
                Cuautitlán Izcalli, Estado de México, México.
              </pre>
            </div>
          </div>
          <div className={classes.textPart}>
            <p className={classes.paragraph}>
              Por este medio declaro mi conformidad con la propuesta del
              producto, de los tiempos establecidos previamente y de los precios
              que recibí por parte de Zendasys. Soy consciente de que cualquier
              servicio extra incrementará el precio final y que esto puede
              modificar los tiempos de entrega.
            </p>
          </div>
          <div className={`${classes.firmas} row`}>
            <div className={`${classes.textoFirmas} col-sm`}>
              <hr style={{ color: "black", background: "black", height: 1 }} />
              <b>{nombrePM}</b>
              <br />
              Firma de Zendasys' Project Manager
            </div>
            <div className={`${classes.textoFirmas} col-sm`}>
              <hr style={{ color: "black", background: "black", height: 1 }} />
              <b>{nombreCiente}</b>
              <br />
              Firma del Cliente
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Firma;
