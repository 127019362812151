import React from "react";
import { makeStyles } from "@material-ui/styles";
import "../../Static/css/App.css";

const useStyles = makeStyles({
  coverPage: {
    textAlign: "center",
    height: "100vh",
    background: "red",
  },
  leftDecoration: {
    width: "11.62%",
    height: "100%",
    float: "left",
  },
  logoImg: {
    width: "21%",
    marginTop: "140px",
    marginRight: "25px",
  },
  nombreProjectoTitulo: {
    fontFamily: "Raleway",
    fontWeight: 600,
    fontSize: 16,
    color: "black",
    marginRight: "25px",
    marginTop: "28px",
  },
  greyBackground: {
    marginTop: 45,
    background: "#E9EAE8",
    padding: 40,
  },
  contenido: {
    fontSize: 13,
    fontWeight: 700,
    marginRight: 125,
  },
});

interface Props {
  nombreProyecto: string;
  return_lis: (val: string[]) => any;
}
const Portada: React.FC<Props> = ({ nombreProyecto, return_lis }) => {
  const classes = useStyles();

  return (
    <>
      {/* <div className="w3-display-container">
        <div className="w3-display-middle"> */}
      <div className="w3-card" style={{ width: "520px", height: "657.75px" }}>
        <img
          className={classes.leftDecoration}
          src={require("../.././Static/img/1.png")}
          alt="left"
        />
        <img
          className={classes.logoImg}
          src={require("../.././Static/img/zendasys_preferente.png")}
          alt="logo"
        />
        <h6 className={classes.nombreProjectoTitulo}>{nombreProyecto}</h6>
        <div className={classes.greyBackground}>
          <h6 className={classes.contenido}>Contenido:</h6>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ul
              style={{
                fontSize: 12,
                marginRight: 50,
                textAlign: "left",
                marginBottom: 43,
              }}
            >
              {return_lis([
                "Interpretación",
                "Objetivo del proyecto",
                "Alcance del proyecto",
                "Metodología",
                "Propuesta",
                "Entregables",
                "Carta Confirmación",
              ])}
            </ul>
          </div>
          {/* </div>
            </div> */}
        </div>
      </div>
    </>
  );
};

export default Portada;
