import {makeStyles} from "@material-ui/styles";
import React from "react";
import "../../Static/css/Controador.css";

const useStyles = makeStyles({
    leftArrowsContent: {float: "left", width: "65%"},
    arrows: {
        float: "right",
        textAlign: "center",
        width: "35%",
        transform: "scale(0.7)",
        WebkitTransform: "scale(0.7)",
    },
    arrow: {
        border: "solid black",
        borderWidth: "0 3px 3px 0",
        display: "inline-block",
        padding: "3px",
    },
    right: {
        transform: "rotate(-45deg)",
        WebkitTransform: "rotate(-45deg)",
    },
    buttonRight: {},
    left: {
        transform: "rotate(135deg)",
        WebkitTransform: "rotate(135deg)",
    },
    buttonLeft: {marginRight: 40, width: "auto"},
    up: {
        transform: "rotate(-135deg)",
        WebkitTransform: "rotate(-135deg)",
    },
    buttonUp: {marginBottom: -10},
    down: {
        transform: "rotate(45deg)",
        WebkitTransform: "rotate(45deg)",
    },
    buttonDown: {marginTop: -10},
});

interface Props {
    nombreProyecto: string;
    setNombreProyecto: (val: string) => void;
    interpretacion:string | undefined;
    setInterpretacion: (val: string) => void;
    objetivo:string | undefined;
    setObjetivo: (val: string) => void;
    objetivoArrow:number | undefined;
    setObjetivoArrow: (val: number) => void;
    alcance:string | undefined;
    setAlcance: (va: string) => void;
    metodologia:string | undefined;
    setMetodologia: (val: string) => void;
    metodologiaTitle:string | undefined;
    setMetodologiaTitle: (val: string) => void;
    setMetodologiaMargin: (val: { up: number; left: number }) => void;
    metodologiaMargin: {
        up: number;
        left: number;
    };
    fasesDesarrollo: {
        weeks: number;
        values: {
            name: string;
            content: string;
            week: number;
        }[];
    };
    setFasesDesarrollo: (val: {
        weeks: number;
        values: {
            name: string;
            content: string;
            week: number;
        }[];
    }) => void;
    iteracionesDesarrollo: string[];
    setIteracionesDesarrollo: (val: string[]) => void;
    propuestas: { nombre: string; content: string }[];
    setPropuestas: (val: { nombre: string; content: string }[]) => void;
    setTiemposEntrega: (val: string) => void;
    setCostos: (val: string) => void;
    tiemposEntrega: string;
    costos: string;
    nombrePM: string;
    setNombrePM: (val: string) => void;
    setNombreCiente: (val: string) => void;
    downloadPDF: (e: any) => void;
    save: (e: any) => void;
}

const Controlador: React.FC<Props> = ({
      nombreProyecto,
      setNombreProyecto, interpretacion,
      setInterpretacion,
    objetivo,
      setObjetivo,
    objetivoArrow,
      setObjetivoArrow,
    alcance,
      setAlcance,
    metodologia,
      setMetodologia,
    metodologiaTitle,
      setMetodologiaTitle,
      metodologiaMargin,
      setMetodologiaMargin,
      setFasesDesarrollo,
      fasesDesarrollo,
      iteracionesDesarrollo,
      setIteracionesDesarrollo,
      propuestas,
      setPropuestas,
      setTiemposEntrega,
      setCostos,
      tiemposEntrega,
      costos,
      nombrePM,
      setNombrePM,
      setNombreCiente,
      downloadPDF,
      save,
  }) => {
    const classes = useStyles();
    const metodologiaValue = 3;

    return (
        <>
            {/* Apartado del nombre */}
            <h3>
                Nombre del Proyecto: <br/>
            </h3>
            {nombreProyecto === "Nombre del Projecto" ? null : (
                <h3 className="nombre_proyecto">{nombreProyecto}</h3>
            )}
            <input
                value={nombreProyecto}
                type="text"
                className="form-control"
                placeholder="Nombre del Proyecto"
                onChange={(e) => setNombreProyecto(e.target.value)}
            />
            {/* SEGUNDA PAGINA */}
            {/* Apartado de la interpretación */}
            <h5 className="mt-4">Interpretación</h5>
            <textarea
                value={interpretacion}
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={3}
                onChange={(e) => setInterpretacion(e.target.value)}
            />
            <br/>
            {/* Apartado de la objetivo */}
            <div className="form-row mb-2">
                <div className="col-7">
                    <h5>Objetivo</h5>
                </div>
                {/* Se usa para ajustar la flechita */}
                <div className="col">
                    <h5>Ajustar Flecha</h5>
                    <input
                        value={objetivoArrow}
                        type="number"
                        className="form-control"
                        defaultValue={0}
                        onChange={(e) => setObjetivoArrow(parseInt(e.target.value))}
                    />
                </div>
            </div>
            <textarea
                value={objetivo}
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={3}
                onChange={(e) => setObjetivo(e.target.value)}
            />
            {/* Apartado de alcance */}
            <h5>Alcance</h5>
            <textarea
                value={alcance}
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={3}
                onChange={(e) => setAlcance(e.target.value)}
            />
            {/* Metodología */}
            <h5>Metodología</h5>
            <div>
                <div className={classes.leftArrowsContent}>
                    <h6>Metodología Title</h6>
                    <input
                        value={metodologiaTitle}
                        type="text"
                        onChange={(e) => setMetodologiaTitle(e.target.value)}
                    />
                </div>
                <div className={classes.arrows}>
                    <button
                        onClick={(e) => {
                            let temp = {...metodologiaMargin};
                            temp.up -= metodologiaValue;
                            setMetodologiaMargin(temp);
                        }}
                        type="button"
                        className={`${classes.buttonUp} btn btn-light`}
                    >
                        <i className={`${classes.arrow} ${classes.up}`}/>
                    </button>
                    <br/>
                    <button
                        type="button"
                        onClick={(e) => {
                            let temp = {...metodologiaMargin};
                            temp.left -= metodologiaValue;
                            setMetodologiaMargin(temp);
                        }}
                        className={`${classes.buttonLeft} btn btn-light`}
                    >
                        <i className={`${classes.arrow} ${classes.left}`}/>
                    </button>
                    <button
                        type="button"
                        onClick={(e) => {
                            let temp = {...metodologiaMargin};
                            temp.left += metodologiaValue;
                            setMetodologiaMargin(temp);
                        }}
                        className={`${classes.buttonRight} btn btn-light`}
                    >
                        <i className={`${classes.arrow} ${classes.right}`}/>
                    </button>
                    <br/>
                    <button
                        type="button"
                        onClick={(e) => {
                            let temp = {...metodologiaMargin};
                            temp.up += metodologiaValue;
                            setMetodologiaMargin(temp);
                        }}
                        className={`${classes.buttonDown} btn btn-light`}
                    >
                        <i className={`${classes.arrow} ${classes.down}`}/>
                    </button>
                </div>
            </div>
            <h6 style={{marginTop: 100}}>Metodología Body</h6>
            <textarea
                value={metodologia}
                className="form-control"
                id="exampleFormControlTextarea1"
                key={"exampleFormControlTextarea1"}
                rows={3}
                onChange={(e) => setMetodologia(e.target.value)}
            />
            {/* Apartado de las Fases del Proyecto */}
            <div style={{marginTop: 20, marginBottom: 80}}>
                <h5 style={{float: "left"}}>Informes de Progreso</h5>
                <button style={{float: "right", marginTop: 7.5}}>+</button>
            </div>
            {fasesDesarrollo.values.map((fase, index) => (
                <>
                    <div key={index} className="form-row">
                        <div className="form-group col">
                            <label style={{fontSize: 14}} htmlFor="inputEmail4">
                                Nombre de la Fase
                            </label>
                            <input
                                style={{width: "140%"}}
                                value={fase.name}
                                type="text"
                                onChange={(e) => {
                                    const temp = {...fasesDesarrollo};
                                    temp.values[index].name = e.target.value;
                                    setFasesDesarrollo(temp);
                                }}
                            />
                        </div>
                        <div className="form-group col">
                            <label
                                style={{fontSize: 14, float: "right"}}
                                htmlFor="inputPassword4"
                            >
                                Tiempo de la fase
                            </label>
                            <input
                                value={fase.week}
                                style={{width: "50%", float: "right"}}
                                type="number"
                                onChange={(e) => {
                                    const temp = {...fasesDesarrollo};
                                    const tempWeek = temp.values[index].week;
                                    try {
                                        if(e.target.value === "") e.target.value = "0"
                                        temp.values[index].week = parseInt(e.target.value);
                                        temp.weeks =
                                            temp.weeks - tempWeek + parseInt(e.target.value);
                                    } catch (err) {
                                        console.log(err)
                                    }
                                    setFasesDesarrollo(temp);
                                }}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col">
                            <label style={{fontSize: 14}} htmlFor="inputEmail4">
                                Contenido
                            </label>
                            <br/>
                            <input
                                style={{width: "68%"}}
                                value={fase.content}
                                type="text"
                                onChange={(e) => {
                                    const temp = {...fasesDesarrollo};
                                    temp.values[index].content = e.target.value;
                                    setFasesDesarrollo(temp);
                                }}
                            />
                        </div>
                    </div>
                </>
            ))}
            {/* Apartado de las Fases de Desarrollo */}
            <div style={{marginTop: 20, marginBottom: 50}}>
                <h5 style={{float: "left"}}>Iteraciones</h5>
                <button
                    onClick={(e) => {
                        e.preventDefault()
                        const temp = [...iteracionesDesarrollo];
                        temp.push("");
                        setIteracionesDesarrollo(temp);
                    }}
                    style={{float: "right", marginTop: 7.5}}
                >
                    +
                </button>
            </div>
            {iteracionesDesarrollo.map((iteracion, index) => (
                <div key={index}>
                    <label>Vuelta {index + 1}</label>
                    <br/>
                    <input
                        type="text"
                        value={iteracion}
                        onChange={(e) => {
                            e.preventDefault()
                            const temp = [...iteracionesDesarrollo];
                            temp[index] = e.target.value;
                            setIteracionesDesarrollo(temp);
                        }}
                    />
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            const temp = [...iteracionesDesarrollo];
                            temp.splice(index, 1);
                            setIteracionesDesarrollo(temp);
                        }}
                        style={{marginLeft: 20}}
                    >
                        -
                    </button>
                    <br/>
                </div>
            ))}
            {/* Apartado de la Propusta */}
            <div style={{marginTop: 20, marginBottom: 70}}>
                <h5 style={{float: "left"}}>Propuestas</h5>
                <button
                    onClick={(e) => {
                        e.preventDefault()
                        const temp = [...propuestas];
                        temp.push({nombre: "", content: ""});
                        setPropuestas(temp);
                    }}
                    style={{float: "right", marginTop: 7.5}}
                >
                    +
                </button>
            </div>

            {propuestas.map((propuesta, index) => (
                <div key={index}>
                    <label>Nombre (13px)</label>
                    <br/>
                    <input
                        key={`${index} inpue`}
                        type="text"
                        value={propuestas[index].nombre}
                        onChange={(e) => {
                            const temp = [...propuestas];
                            temp[index].nombre = e.target.value;
                            setPropuestas(temp);
                        }}
                    />
                    <br/>
                    <label className="mt-2">Content</label>
                    <textarea
                        key={`${index} tarea`}
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows={3}
                        value={propuestas[index].content}
                        onChange={(e) => {
                            const temp = [...propuestas];
                            temp[index].content = e.target.value;
                            setPropuestas(temp);
                        }}
                    />
                </div>
            ))}
            {/* Apartado de la Tiempo y Costos */}
            <h5 style={{float: "left"}}>Tiempos</h5>
            <textarea
                value={tiemposEntrega}
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={3}
                onChange={(e) => setTiemposEntrega(e.target.value)}
            />
            <h5 style={{float: "left"}}>Costos</h5>
            <textarea
                value={costos}
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={3}
                onChange={(e) => setCostos(e.target.value)}
            />
            {/* Apartado de nombre cliente y PM*/}
            <br/>
            <h5>Nombre del PM</h5>
            <input value={nombrePM} onChange={(e) => setNombrePM(e.target.value)}/>
            <br/>
            <h5>Nombre Cliente</h5>
            <input onChange={(e) => setNombreCiente(e.target.value)}/>
            <br/>
            <br/>
            {/* Final Buttons */}
            <button
                type="submit"
                className="btn mt-3"
                onClick={save}
                style={{
                    background: "#000D21",
                    color: "white",
                    fontWeight: 550,
                    fontSize: 12,
                    float: "left",
                }}
            >
                Guardar
            </button>
            <button
                type="submit"
                className="btn mt-3"
                onClick={downloadPDF}
                style={{
                    background: "#000D21",
                    color: "white",
                    fontWeight: 550,
                    fontSize: 12,
                    float: "right",
                }}
            >
                Descargar PDF
            </button>
        </>
    );
};

export default Controlador;
